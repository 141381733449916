<template>
  <div>
    <el-dialog
      title="编辑"
      append-to-body
      :visible.sync="multiEditDialogVisible"
      width="1500px"
      @open="openInit"
      @close="cancel"
      :close-on-click-modal="false"
      class="edit-copy-dialog"
    >
      <div class="h-full">
        <el-row
          :gutter="10"
          class="h-full"
        >
          <el-col
            :span="6"
            class="editTree px-2"
          >
            <adsTree
              ref="adsTree"
              @setCurrentData="setCurrentData"
              :treeData="treeData"
              @validateData="validateData"
              class="h-full overflow-y-auto overflow-x-hidden"
          /></el-col>
          <el-col
            :span="18"
            class="editCon"
            style="height: inherit"
          >
            <component
              :is="isComponent"
              :currentData="currentData"
              :enumConstants="enumConstants"
              class="h-full overflow-auto"
              :createAdAccount="createAdAccount"
              ref="levelComponent"
            ></component>
            <!-- <campaign />
            <adGroup /> -->
            <!-- <ads /> -->
          </el-col>
        </el-row>
      </div>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import adsTree from '@/views/google/adManagement/components/adsTree.vue';
import campaign from '@/views/google/adManagement/components/campaign.vue';
import adGroup from '@/views/google/adManagement/components/adGroup.vue';
import ads from '@/views/google/adManagement/components/ads.vue';
import { adConstMapGG } from '@/api/google/create';
import { uploadDraft, saveDrafts } from '@/api/google/adManagement';
import { bidStrategy, callToActionList } from '@/views/google/create/index.js';
import { debounce } from 'lodash';
export default {
  props: {
    multiEditDialogVisible: {
      type: Boolean,
      default: false,
    },
    currentSelect: {
      type: Array,
      default: () => [],
    },
    accountId: {
      type: [String, Array, Number],
      default: '',
    },
    API: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      //   dialogVisible: true,
      level: 'campaign',
      currentData: {},
      treeData: [],
      enumConstants: {
        countries: [],
        languages: [],
        bidStrategy,
      },
    };
  },
  components: { adsTree, campaign, adGroup, ads },
  computed: {
    isComponent() {
      return this.level;
    },
    createAdAccount() {
      return {
        id: this.accountId.toString(),
      };
    },
  },
  methods: {
    /** 打开弹窗 */
    openInit() {
      // 获取树状结构
      this.getTreeList();
      this.getEnumConstants();
    },
    cancel() {
      this.$emit('update:multiEditDialogVisible', false);
    },
    validateData: debounce(async function (level, callback) {
      try {
        const isValid = await this.$refs.levelComponent.checkForm();
        if (!isValid) {
          this.$message.warning('请检查当前页面的必填项是否填写完整');
        }

        if (callback && typeof callback === 'function') {
          // 调用传递过来的回调函数并传入更新的 isValid 值
          callback(isValid);
        }
      } catch (error) {
        console.error('Validation error:', error);
      }
    }, 300),
    /**处理编辑后数据 */
    getEditParams() {
      let newData = JSON.parse(JSON.stringify(this.treeData));
      newData.forEach((campaign) => {
        delete campaign.children;
        campaign.adGroups.forEach((adGroup) => {
          delete adGroup.children;
          adGroup.groupCriterion.target.locations = adGroup.groupCriterion.target.locations.map((v) => {
            return {
              id: v.id || v.criteria_id,
              name: v.name || v.name_zhcn,
              code: v.code || v.country_code,
            };
          });
          adGroup.adGroupAds.forEach((adGroupAd) => {
            delete adGroupAd.children;
            if (adGroupAd.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD') {
              // 行动号召自动的时候赋值为空
              if (!adGroupAd.demandGenAd.callToActions.find((v) => v.key)) {
                adGroupAd.demandGenAd.callToActions = null;
              }
              adGroupAd.demandGenAd.videoAssets = adGroupAd.demandGenAd.videoAssets.map((video) => {
                console.log(video, 'videovideovideovideovideo');
                return {
                  videoId: video.videoId,
                  name: video.name || video.videoName || video.videoTitle, //复制获取的字段是name,新选择的是videoName
                  youtubeChannelId: video.youtubeChannelId,
                  thumbnail: video.thumbnail,
                };
              });
            } else {
              // 行动号召自动的时候赋值为空
              if (!adGroupAd.demandGenAd.callToAction?.key) {
                adGroupAd.demandGenAd.callToAction = null;
              }
            }
          });
        });
      });
      return newData;
    },
    async savaNewData(API) {
      this.$showLoading();
      let res = await API(this.getEditParams());
      this.$hideLoading();
      if (res.code == 0) {
        this.$message({
          type: 'success',
          message: '操作成功,请稍后刷新列表查看',
        });
        this.cancel();
        this.$emit('getList');
      }
    },
    async sure() {
      const isValid = await this.$refs.levelComponent.checkForm();
      if (isValid) {
        this.$confirm('你想怎么保存当前信息?', '提示', {
          confirmButtonText: '立即上传到google',
          cancelButtonText: '保存到草稿',
          distinguishCancelAndClose: true,
          closeOnClickModal: false,
        })
          .then(() => {
            this.savaNewData(uploadDraft);
          })
          .catch((action) => {
            console.log(action);
            if (action == 'cancel') {
              this.savaNewData(saveDrafts);
            }
          });
      } else {
        this.$message.warning('请检查当前页面的必填项是否填写完整');
      }

      //   this.cancel();
    },
    /**
     * 接收树状结构返回的层级
     */
    setCurrentData(data) {
      this.level = data.level;
      this.currentData = data;
    },
    /** 获取子级的元素 */
    getChildren(node, level) {
      if (level === 'campaign' && node.adGroups) {
        return node.adGroups;
      }
      if (level === 'adGroup' && node.adGroupAds) return node.adGroupAds;
      return node.children || [];
    },
    /**获取下一个level*/
    setNextLevel(level) {
      return level == 'campaign' ? 'adGroup' : 'ads';
    },
    /** 处理数据便于树状渲染 */
    transformTreeForRender(data, level = 'campaign') {
      return data.map((item, index) => {
        console.log(item);
        item.disabled = !!item.thirdId;
        item.level = level;
        // 设置children属性
        item.children = this.getChildren(item, level);
        // 如果有子节点，递归处理
        if (item.children && item.children.length > 0) {
          this.transformTreeForRender(item.children, this.setNextLevel(level));
        }
        return item;
      });
    },
    /** 获取树状结构 */
    async getTreeList() {
      try {
        const res = JSON.parse(JSON.stringify(this.currentSelect));
        res.forEach((item, index) => {
          item.index = index;
          item.adGroups.forEach((adGroup, ind) => {
            adGroup.groupCriterion = adGroup.groupCriterion || {
              target: {
                locations: [],
              },
              audience: {},
            };
            adGroup.groupCriterion.target.locations = adGroup.groupCriterion.target.locations.map((v) => {
              return {
                ...v,
                criteria_id: v.id,
                name_zhcn: v.name,
                country_code: v.code,
                index: index + '-' + ind,
              };
            });
            adGroup.adGroupAds.forEach((ads, indexAd) => {
              ads.index = ind + '-' + indexAd;
              ads.demandGenAd.callToActions =
                ads.demandGenAd.callToActions && ads.demandGenAd.callToActions.length
                  ? ads.demandGenAd.callToActions.map((v) => {
                      console.log(callToActionList);
                      let action = callToActionList.find((action) => action.value == v.key);
                      v.action = action ? action.key : '自动';
                      return v;
                    })
                  : [];
              ads.demandGenAd.callToAction = ads.demandGenAd.callToAction
                ? ads.demandGenAd.callToActions.find((v) => {
                    let action = callToActionList.find((action) => action.value == v.key);
                    v.action = action.key;
                    return v;
                  })
                : null;

              // 处理图片
              ads.demandGenAd.videoAssets =
                ads.demandGenAd.videoAssets && ads.demandGenAd.videoAssets.length
                  ? ads.demandGenAd.videoAssets.map((v) => {
                      return { ...v, type: 'youtube', status: 'succeeded', process: 100 };
                    })
                  : [];
            });
          });
        });
        this.treeData = this.transformTreeForRender(res);
        this.$nextTick(() => {
          this.$refs.adsTree.setDefaultData(this.treeData[0]);
        });
      } finally {
        this.$emit('handleLoading', false);
      }
    },
    /** 获取基本信息 */
    getEnumConstants() {
      adConstMapGG().then((res) => {
        if (res.code == 0) {
          this.enumConstants.countries = res.data.defaultCountries;
          this.enumConstants.languages = res.data.defaultLanguages;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.edit-copy-dialog {
  .el-dialog {
    height: 92vh;
    margin-top: 2vh !important;
    .el-dialog__body {
      height: 85%;
      overflow: hidden;
    }
  }
}
.createWrap {
  .el-input,
  .el-select {
    width: 80%;
  }
  .el-select > .el-input {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
.createWrap {
  .editTree {
    min-height: 520px;
    max-height: 700px;
    border-right: 1px solid #ccc;
    padding-right: 10px;
    overflow-y: auto;
    height: inherit;
  }
  .editCon {
    min-height: 520px;
    max-height: 700px;
    overflow-y: scroll;
    border-right: 1px solid #ccc;
    padding: 0 20px;
    overflow-x: hidden;
    height: inherit;
  }
}
</style>
