<template>
  <div class="container1 h-full">
    <el-container
      style="background: #fff"
      class="h-full"
    >
      <el-aside
        class="aside"
        width="240px"
        style="border-right: 1px solid #efefef"
      >
        <div class="aside-content">
          <h2 class="title">广告管理</h2>
          <div class="account-tree">
            <account-tree
              :check-strictly="false"
              is-radio
              show-create
              @check="checkChange"
              :credential-account-hide="false"
            ></account-tree>
          </div>
        </div>
      </el-aside>
      <el-container>
        <div class="adManagement-container">
          <el-row class="datePicker">
            <ad-date-range-pick
              v-model="dateRange"
              size="small"
            ></ad-date-range-pick>
          </el-row>
          <el-row>
            <!-- 头部tab-->
            <el-tabs
              type="border-card"
              v-model="activeTab"
              @tab-click="handleClickTab"
            >
              <el-tab-pane
                name="campaign"
                :lazy="true"
              >
                <span slot="label">
                  广告系列
                  <el-button
                    type="primary"
                    round
                    size="mini"
                    v-if="campaignNum"
                  >
                    已选{{ campaignNum }}项
                  </el-button>
                  <el-button
                    type="info"
                    round
                    size="mini"
                    v-else
                  >
                    {{ numObj.campaigns }}
                  </el-button>
                  <el-button
                    class="el-icon-refresh"
                    type="text"
                    @click="refreshTable('campaignTable')"
                    v-if="activeTab == 'campaign'"
                  ></el-button>
                </span>
                <ad-table
                  :date-range="dateRange"
                  level="campaign"
                  :account-id="accountId"
                  :currentNodeCampagin="currentNodeCampagin"
                  :init-data="initData"
                  :fields="fields"
                  @selection-change="handleSelectionChange"
                  :activeLevel="activeTab"
                  :adsCountObj="adsCountObj"
                  ref="campaignTable"
                  @select-tab="selectTab"
                  @getNum="getNum"
                ></ad-table>
              </el-tab-pane>
              <el-tab-pane
                name="adset"
                :lazy="true"
              >
                <span slot="label">
                  广告组
                  <el-button
                    type="primary"
                    round
                    size="mini"
                    v-if="adsetNum"
                  >
                    已选{{ adsetNum }}项
                  </el-button>
                  <el-button
                    type="info"
                    round
                    size="mini"
                    v-else
                  >
                    {{ numObj.adsets }}
                  </el-button>
                  <el-button
                    class="el-icon-refresh"
                    type="text"
                    @click="refreshTable('adsetTable')"
                    v-if="activeTab == 'adset'"
                  ></el-button>
                </span>
                <ad-table
                  :date-range="dateRange"
                  level="adset"
                  :account-id="accountId"
                  :currentNodeCampagin="currentNodeCampagin"
                  :init-data="initData"
                  :fields="fields"
                  @selection-change="handleSelectionChange"
                  :activeLevel="activeTab"
                  :adsCountObj="adsCountObj"
                  :select-ids="selectIds"
                  ref="adsetTable"
                  @click="refreshTable('adsetTable')"
                  @select-tab="selectTab"
                  @getNum="getNum"
                ></ad-table>
              </el-tab-pane>
              <el-tab-pane
                name="ad"
                :lazy="true"
              >
                <span slot="label">
                  广告
                  <el-button
                    type="primary"
                    round
                    size="mini"
                    v-if="adNum"
                  >
                    已选{{ adNum }}项
                  </el-button>
                  <el-button
                    type="info"
                    round
                    size="mini"
                    v-else
                  >
                    {{ numObj.ads }}
                  </el-button>
                  <el-button
                    class="el-icon-refresh"
                    type="text"
                    @click="refreshTable('adTable')"
                    v-if="activeTab == 'ad'"
                  ></el-button>
                </span>
                <ad-table
                  :date-range="dateRange"
                  level="ad"
                  :account-id="accountId"
                  :currentNodeCampagin="currentNodeCampagin"
                  :init-data="initData"
                  :fields="fields"
                  @selection-change="handleSelectionChange"
                  :activeLevel="activeTab"
                  :adsCountObj="adsCountObj"
                  :select-ids="selectIds"
                  ref="adTable"
                  @click="refreshTable('adTable')"
                  @getNum="getNum"
                ></ad-table>
              </el-tab-pane>
              <el-tab-pane name="drafBox">
                <span slot="label">
                  草稿箱
                  <!-- <el-button type="info" round size="mini">
                    {{ adsCountObj.drafts }}
                  </el-button> -->
                </span>
                <div class="tableWrap">
                  <draft-box
                    :account-id="accountId"
                    :currentNodeCampagin="currentNodeCampagin"
                    :dateRange="dateRange"
                    @get-draft-num="getDraftNum"
                  ></draft-box>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-row>
        </div>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { reportConsts, getAdsCount } from '@/api/google/adManagement';
import { adConstsMapGG } from '@/api/google/ceateAdGg.js';
import accountTree from '@/components/google/accountTree.vue';
import AdDateRangePick from '@/views/adManagement/components/AdDateRangePick';
import AdTable from '@/components/google/AdTable';
import DraftBox from '@/components/google/DraftBox.vue';

export default {
  components: {
    AdTable,
    AdDateRangePick,
    DraftBox,
    accountTree,
  },
  data() {
    return {
      currentNodeCampagin: {}, //当前选择的系列节点
      selectedRowScopes: [],
      dateRange: {
        actionAttributionWindows: [],
        dateRange: [],
      },
      accountId: '',
      level: 'campaign',
      activeTab: this.$store.getters.googleAdActiveTab,
      fields: [],
      params: {
        account_id: this.accountId,
        action_attribution_windows: [],
        after: '',
        date_end: '2020-10-01',
        date_start: '2020-02-20',
        fields: [],
        filtering: [],
        object_filtering: [],
        keyword: '',
        level: 'campaign',
        page_length: 50,
        sort: [],
      },
      initData: {},
      // 广告数量
      adsCountObj: {},
      selectCampaigns: [],
      selectAdsets: [],
      selectAds: [],
      selectIds: {
        selectCampaigns: [],
        selectAdsets: [],
        selectAds: [],
      },
      filterShow: false,
      filterName: '',
      saveFilterShow: false,
      numObj: {},
    };
  },
  computed: {
    campaignNum() {
      return this.selectCampaigns.filter((v) => v.level == 'campaign').length;
    },
    adsetNum() {
      return this.selectAdsets.filter((v) => v.level == 'adset').length;
    },
    adNum() {
      return this.selectAds.filter((v) => v.level == 'ad').length;
    },
  },
  // 注册自定指令
  directives: {
    'infinite-scroll': {
      bind(el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper');
        selectWrap.addEventListener('scroll', function () {
          let sign = binding.value.distance;
          const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value.load();
          }
        });
      },
    },
  },
  watch: {
    level: {
      handler() {
        this.selectIds.selectCampaigns = this.selectCampaigns.filter((v) => v.level == 'campaign');
        this.selectIds.selectAdsets = this.selectAdsets.filter((v) => v.level == 'adset');
        this.selectIds.selectAds = this.selectAds.filter((v) => v.level == 'ad');
      },
    },
    'this.$store.getters.googleAdActiveTab'() {
      this.activeTab = this.$store.getters.googleAdActiveTab;
      if (this.activeTab !== 'drafBox') {
        this.level = this.activeTab;
      }
    },
    dateRange() {
      this.params.action_attribution_windows = this.dateRange.actionAttributionWindows;
      this.params.date_start = this.dateRange.dateRange[0];
      this.params.date_end = this.dateRange.dateRange[1];
      localStorage.setItem('adDateRange', JSON.stringify(this.dateRange));
    },
  },
  async mounted() {
    if (localStorage.getItem('adDateRange')) {
      this.dateRange = { ...JSON.parse(localStorage.getItem('adDateRange')) };
    }
    this.$store.dispatch('app/openSideBar');
    await this.getInitData();
    // ctrl+d 快捷打开复制广告系列-广告组-广告
    this.listenerCtrlD();
    //   获取常量接口
    this.f_getConstData();
  },
  // 实例销毁之前对点击事件进行解绑
  beforeDestroy() {
    // window.removeEventListener('click', this.clickOther);
    window.removeEventListener('keydown', this.ctrlDMethod, true);
  },
  methods: {
    //   获取常量
    async f_getConstData() {
      let res = await adConstsMapGG();
      if (res.code == 0) {
        let array = this.f_callToActionData(res.data);
        let data = res.data;
        data.defaulcallToActionData = array;
        this.$store.commit('initData/setConstDataGoogle', data);
        console.log(data);
      }
    },
    f_callToActionData(res) {
      let data = res.defaultCountries;
      let dataCallToAction = res.defaultCallToActions;
      if (data && data.length > 0) {
        // 过滤出可用的国家
        let arrayCountry = data.map((item) => item.defaultLocalCode);
        dataCallToAction.map((c) => {
          if (arrayCountry.includes(c.locale)) {
            let k = arrayCountry.indexOf(c.locale);
            data[k].shopNow = c.shopNow;
          }
        });
      }

      return data.filter((item) => item.shopNow);
    },
    checkChange(accounts, data) {
      console.log(accounts, data);
      this.currentNodeCampagin = { ...data };
      this.accountId = accounts;

      this.getAdsCount();
      // this.getList();
    },
    getAdsCount() {
      //   this.$showLoading();
      getAdsCount({
        accountId: this.accountId.toString(),
      })
        .then((res) => {
          //   this.$hideLoading();
          if (res.code == 0) {
            this.numObj = res.data;
            this.adsCountObj = { ...res.data };
          }
        })
        .catch((err) => {
          this.$hideLoading();
          console.log(err);
        });
    },
    ctrlDMethod() {
      if (event.key == 'd' && event.ctrlKey) {
        event.preventDefault();
        if (!this.selectedRowScopes.length) return false;
        if (this.$refs[`${this.activeTab}Table`] && this.selectedRowScopes.length)
          this.$refs[`${this.activeTab}Table`].editCopyShow = true;
      }
    },
    listenerCtrlD() {
      if (this.selectedRowScopes && this.selectedRowScopes.length) {
        window.addEventListener('keydown', this.ctrlDMethod, true);
      }
    },
    isArrayChange(a, b) {
      if (a.length !== b.length) return true;
      let c = b.concat(a);
      if (!c.some((item) => a.some((aitem) => aitem.id === item.id))) return false;
      if (!c.some((item) => b.some((aitem) => aitem.id === item.id))) return false;
      return true;
    },
    open() {},
    formatedCellValue(row, columnDefine) {
      let keyName = columnDefine.keyName;
      let type = columnDefine.type;
      let v = row[keyName];
      let empty = false;
      if (v === undefined || v === null) empty = true;
      switch (type) {
        case 'STRING':
          if (empty) return '--';
          return v;
        case 'MONEY':
          if (empty) return '--';
          return this.formatedMoney(v);
        case 'PERCENTAGE':
          if (empty) return '--';
          return parseFloat(v).toFixed(2) + '%';
        case 'INT':
          if (empty) return '--';
          return parseInt(v);
        case 'DECIMAL':
          if (empty) return '--';
          return parseFloat(v).toFixed(2);
        default:
          return v;
      }
    },
    formatedMoney(v) {
      return '$' + (parseFloat(v) / 100).toFixed(2);
    },
    // 获取实时数量
    getNum(level, num) {
      this.numObj[level] = num;
    },
    // 刷新表格数据
    refreshTable(ref) {
      this.$refs[ref].debouncedReloadTable();
    },
    // 获取草稿数量
    getDraftNum(v) {
      this.adsCountObj.drafts = v;
    },
    // field
    operatorName(v, tag) {
      return v
        ? v.filtering.filter((m) => m.key == tag.operator)[0]
          ? v.filtering.filter((m) => m.key == tag.operator)[0].desc
          : ''
        : '请选择';
    },
    // 显示筛选条件符号下拉
    showFiltering(tag) {
      let list = this.initData.FilterFields?.filter((v) => v.keyName == tag.field);
      if (list.length) {
        return list[0].filtering;
      } else {
        return [];
      }
    },
    // 获取常量数据
    async getInitData() {
      await reportConsts().then((res) => {
        this.initData = res.data;
      });
    },
    // 监听选择表格的选择项
    handleSelectionChange(level, selection) {
      this.selectedRowScopes = [...selection];
      if (level == 'campaign') {
        this.selectCampaigns = selection;
        this.selectAdsets = [];
        this.selectAds = [];
      } else if (level == 'adset') {
        this.selectAdsets = selection;
        this.selectAds = [];
      } else if (level == 'ad') {
        this.selectAds = selection;
      }
      // ctrl+d 快捷打开复制广告系列-广告组-广告
      this.listenerCtrlD();
    },
    handleClickTab(v) {
      this.$store.commit('googleAdActiveTab/setActiveTab', v.name);
      if (v.name !== 'drafBox') {
        if (v.name == 'adset' && v.id) {
          this.selectIds.selectCampaigns = v.id;
        } else if (v.name == 'ad' && v.id) {
          this.selectIds.selectAdsets = v.id;
        }
        this.level = this.activeTab;

        if (v.name == 'campaign') {
          if (this.$refs.campaignTable) this.$refs.campaignTable.tabChangeReloadTable('campaign');
        }
        if (v.name == 'adset') {
          if (this.$refs.adsetTable) this.$refs.adsetTable.tabChangeReloadTable('adset');
        }
        if (v.name == 'ad') {
          if (this.$refs.adTable) this.$refs.adTable.tabChangeReloadTable('ad');
        }
      }
    },
    selectTab(v) {
      this.activeTab = v.name;
      if (v.name == 'adset') {
        this.$store.commit('googleAdActiveTab/setSelectCampaignNew', [v.row]);
      }
      if (this.level == 'ad') {
        this.$store.commit('googleAdActiveTab/setSelectAdsetsNew', [v.row]);
      }
      this.$store.commit('googleAdActiveTab/setActiveTab', v.name);

      if (v.name !== 'drafBox') {
        if (v.name == 'adset' && v.row) {
          this.selectIds.selectCampaigns = [v.row];
          if (this.$refs.adsetTable) this.$refs.adsetTable.tabChangeReloadTable('adset');
        } else if (v.name == 'ad' && v.row) {
          this.selectIds.selectAdsets = [v.row];
          if (this.$refs.adTable) this.$refs.adTable.tabChangeReloadTable('ad');
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container1 {
  .aside {
    position: relative;
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
    }
    .title {
      font-size: 18px;
      height: 60px;
      line-height: 60px;
      text-indent: 18px;
      border-bottom: 1px solid #efefef;
      margin: 0;
    }
    .account-tree {
      padding-top: 4px;
      flex: 1;
      overflow: hidden;
    }
    ::v-deep .el-tree-node__content {
      align-items: flex-start;
      padding: 4px 0;
      padding-right: 8px;
      height: auto;
      .el-tree-node__label {
        word-break: break-all;
        white-space: break-spaces;
      }
    }
  }
  .flex-btw {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content-header {
    position: relative;
    padding: 0;
    display: flex;
    ::v-deep .el-tabs {
      flex: 1;
    }
    ::v-deep .el-tabs__header {
      margin: 0;
    }
    ::v-deep .el-tabs__item {
      height: 59px;
      line-height: 58px;
    }
    .border-bottom {
      width: 10px;
      height: 60px;
      border-bottom: 1px solid #e4e7ed;
    }
    .count-tag {
      color: #fff;
      background: #999;
      padding: 4px 10px;
      font-size: 12px;
      line-height: 1;
      border-radius: 10px;
      margin-left: 6px;
    }
  }
  .button-groups {
    position: absolute;
    right: 10px;
    top: 14px;
    display: flex;
    align-content: center;
    .radios {
      padding-right: 8px;
    }
  }
  .status-bar {
    padding-bottom: 10px;
  }
  .groups {
    display: flex;
    align-items: center;
    .cust-tag {
      background: #ecf5ff;
      color: #333;
      font-size: 12px;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      padding: 0 10px;
      margin-left: 10px;
      &-title {
        color: #409eff;
        font-size: 12px;
      }
      &.transparent {
        background: transparent;
      }
    }
  }
  .input {
    width: 200px;
    margin-left: 10px;
  }
  .pagination {
    margin-top: 10px;
  }
  ::v-deep .el-date-editor--daterange.el-input__inner {
    width: 220px;
  }
}
</style>
<style lang="scss">
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
.adManagement-container .el-tabs__content {
  padding: 0;
}

.adManagement-container {
  position: relative;
  .el-table .caret-wrapper {
    width: 0;
  }
  .datePicker {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 2;
  }
}

.toolsCheckTips {
  .el-checkbox,
  .el-radio {
    line-height: 36px;
  }
}

.dropselect {
  .el-select-dropdown__item {
    padding: 0;

    span {
      display: block;
      padding: 0 20px;
    }
  }
}

.statusWarp {
  display: flex;
  align-items: center;

  .pointer {
    flex: 8px 0 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.breakdownList {
  li {
    line-height: 26px;
  }
}

.cusFilter {
  padding: 0 10px 0 20px;

  .cusFilterList {
    .cusFilterItem {
      margin-bottom: 10px;
    }
  }
}

.el-dropdown-menu__item {
  &.active {
    background: #ecf5ff;
    color: #66b1ff;
  }

  .columnName {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      p {
        display: block;
      }
    }

    div {
      p {
        flex: 60px 0 0;
        display: none;

        i {
          margin: 0 5px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}

.tagInput {
  width: 30px;

  input {
    width: auto;
    border: none;
    outline: none;
    background: transparent;
    padding: 0 5px;
    width: 30px;
    border-bottom: 1px dashed #fff;
    color: #fff;
    height: 18px;
    line-height: 18px;
  }
}

.el-popover {
  min-width: 100px;
}
</style>
<style lang="scss" scoped>
.tableWrap {
  padding: 16px;
  padding-top: 0;

  .loadMore {
    text-align: center;
    line-height: 28px;
    color: #999;
  }
}

.el-table .caret-wrapper {
  width: 0;
}

.adManagement-container {
  width: 100%;
  height: calc(100vh - 124px);
  background: #fff;

  .filterWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ecf5ff;

    .el-image {
      flex: 14px 0 0;
      margin-left: 5px;
    }

    .paramsFilters {
      flex: 1;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .el-tag {
        margin: 3px;
        display: flex;
        align-items: center;

        .checkbox-group {
          padding: 0 10px;

          .el-checkbox {
            display: block;
          }
        }
      }
    }

    .rightIcon {
      flex: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 15px;
        font-weight: bold;
        margin: 0 5px;
        cursor: pointer;
        color: #666;
      }
    }
  }

  .el-tabs__item {
    .el-button {
      padding: 4px 10px;
      margin-left: 5px;
    }
  }

  .table-top-bar {
    // position: absolute;
    // top: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    // height: 32px;
    padding: 16px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightTools {
      flex: 260px 0 0;
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;

      .tools {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .toolsItem {
          margin: 0 4px;
        }
      }

      .searchInput {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .el-button {
          color: #666;
          font-size: 14px;
          margin: 0 5px;
        }
      }
    }

    .midFilter {
      flex: 1;
      padding: 0 10px;
    }

    .table-top-bar-right {
      flex: 1 1 0%;
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.3s linear 0s;
      // padding-right: 140px;
      .filter-btn {
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 12px;
        color: #8c8c8c;
        margin-left: 12px;
        padding: 0 4px;
        border-radius: 2px;
        transition: all 0.3s linear 0s;
        cursor: pointer;

        img {
          margin-right: 4px;
        }
      }

      .filter-btn:hover {
        background: #eaeaed;
      }

      .filter-operation {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        width: auto;
        padding-left: 10px;
        transition: all 0.3s linear 0s;

        .filter-operation-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          transition: all 0.3s linear 0s;
          cursor: pointer;

          i {
            color: gray;
          }
        }

        .filter-operation-item:hover {
          background: #eaeaed;
        }
      }
    }
  }
}
</style>
